<template>
    <div>
        <SudosuFrame />
        <CountryLaunchingBanner />
        <UnsubscribeInvitationBanner />
        <NavbarUnlogged :prismic-document="navbar!" theme="strategy" />
        <slot />
        <!-- Event when logged in, we want to display this footer -->
        <FooterUnlogged :prismic-document="footer!" :always-visible="true" />
    </div>
</template>

<script setup lang="ts">
    import {ref, computed, inject} from 'vue';
    import {useAsyncData} from '#imports';
    import {FooterUnlogged, NavbarUnlogged, SudosuFrame} from '#components';
    import CountryLaunchingBanner from '~/components/CountryLaunchingBanner.vue';
    import {usePrismicDocument} from '~/composables/content/usePrismicDocument';
    import UnsubscribeInvitationBanner from '~/components/UnsubscribeInvitationBanner.vue';
    import type {PrismicDocument} from '@prismicio/types';

    const navbar = ref<PrismicDocument | undefined | null>();
    const footer = ref<PrismicDocument | undefined | null>();

    const {fetchDocument: fetchHeader} = usePrismicDocument('maltStrategyHeader');
    const {fetchDocument: fetchFooter} = usePrismicDocument('maltStrategyFooter');

    const {data: navbarData} = await useAsyncData('maltStrategyHeader', () => fetchHeader());
    const {data: footerData} = await useAsyncData('maltStrategyFooter', () => fetchFooter());
    navbar.value = navbarData.value;
    footer.value = footerData.value;
</script>
